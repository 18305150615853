.root {
	position: fixed;
	left: 20px;
	bottom: 20px;
	background: var(--vkws_landing--dialog--bg);
	padding: var(--vkws_landings--horizontal-padding);

	border-radius: 16px;

	max-width: 280px;

	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.07), 0 4px 24px rgba(0, 0, 0, 0.15);
	display: block;

	height: auto;
}

.title {
	margin-top: 0;
	margin-bottom: 16px;
	color: var(--vkws_landing--dialog--title--color);
}

.description {
	margin-top: 0;
	color: var(--vkws_landing--dialog--description--color);
}

.controls {
	margin-top: 16px;
}

@media screen and (min-width: 1024px) {
	.root {
		padding: 24px 28px;
		max-width: 400px;
	}
}
