@font-face {
	font-family: "VK Sans Display";
	src: url("./VK_Sans_Display_Bold.woff2") format("woff2"),
	url("./VK_Sans_Display_Bold.woff") format("woff");
	font-weight: 700;
	font-display: swap;
}

@font-face {
	font-family: "VK Sans Display";
	src: url("./VK_Sans_Display_DemiBold.woff2") format("woff2"),
	url("./VK_Sans_Display_DemiBold.woff") format("woff");
	font-weight: 600;
	font-display: swap;
}

@font-face {
  font-family: "VK Sans Display";
  src: url("./VK_Sans_Display_Medium.woff2") format("woff2"),
    url("./VK_Sans_Display_Medium.woff") format("woff");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "VK Sans Display";
  src: url("./VK_Sans_Display_Regular.woff2") format("woff2"),
    url("./VK_Sans_Display_Regular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "VK Sans Display";
  src: url("./VK_Sans_Display_Regular.woff2") format("woff2"),
    url("./VK_Sans_Display_Regular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
}


@font-face {
  font-family: "VK Sans Display";
  src: url("./VK_Sans_Display_Light.woff2") format("woff2"),
    url("./VK_Sans_Display_Light.woff") format("woff");
  font-weight: 300;
  font-display: swap;
}

/* Roboto Fonts */

@font-face {
	font-family: "Roboto Mono";
	src: url("./RobotoMonoThin.woff") format("woff");
	font-weight: 100;
	font-display: swap;
}

@font-face {
	font-family: "Roboto Mono";
	src: url("./RobotoMonoLight.woff") format("woff");
	font-weight: 300;
	font-display: swap;
}

@font-face {
	font-family: "Roboto Mono";
	src: url("./RobotoMonoRegular.woff") format("woff");
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: "Roboto Mono";
	src: url("./RobotoMonoMedium.woff") format("woff");
	font-weight: 500;
	font-display: swap;
}

@font-face {
	font-family: "Roboto Mono";
	src: url("./RobotoMonoBold.woff") format("woff");
	font-weight: 700;
	font-display: swap;
}
