html,
body {
	padding: 0;
	margin: 0;
	background: var(--vkws_landings--bg_body);
	color: var(--vkws_landings--color_body);
	font-family: "VK Sans Display", "Proxima Nova", Arial, Helvetica, "Helvetica Neue", sans-serif;
	font-weight: normal;
}

button {
	font-family: "VK Sans Display", "Proxima Nova", Arial, Helvetica, "Helvetica Neue", sans-serif;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

html {
	height: 100%;
}
body {
	min-height: 100%;
}

#__next {
	min-height: 100vh;
}

.grecaptcha-badge {
	visibility: hidden;
}
