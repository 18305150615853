.dialog {
	padding: 28px 32px 32px;
}

.close-button {
	display: flex;
	justify-content: center;
	align-items: center;
	right: 12px;
	top: 12px;
	width: 48px;
	height: 48px;
}

@media screen and (min-width: 1024px) {
	.dialog:not(.state_full-screen) {	
		width: 520px;
	}
}
