.root {
	opacity: 0;
	visibility: hidden;
	pointer-events: none;

	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	overflow-x: hidden;
	overflow-y: auto;

	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	/*Prevent dialog overflow on screens with small height with `center`*/
	/*We will position dialog with margins instead*/
	justify-content: flex-start;
	z-index: var(--vkws_landings--dialogs--z-index);
}

.state_opened {
	opacity: 1;
	visibility: visible;
}


.state_disable-backdrop-scroll {
	pointer-events: auto;
}

@media screen and (min-width: 1024px) {
	.position_center {
		margin-left: auto;
		margin-right: auto;
	}

	.root::before {
		content: '';
		position: fixed;
		background: var(--vkws_landing--dialog--backdrop-bg);
		backdrop-filter: blur(4px) saturate(150%);
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
	}

	/*State with click-through backdrop*/
	.root.state_disabled-backdrop::before {
		display: none;
	}

	.root.stateDisabledBackdropScroll {
		pointer-events: auto;
	}
}
