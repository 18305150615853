.container {
    display: flex;
    cursor: pointer;
}

.avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
	overflow: hidden;
    margin-right: 12px;
}

.info {
    display: flex;
    flex-direction: column;
    color: var(--vkws_landing--dialog--title--color);
}

.name {
    display: flex;
}

.nameText {
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    margin-right: 2px;
}

.email {
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    opacity: .7;
}
