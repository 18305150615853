.wrapper {
	position: relative;
}

.nameplate {
	display: flex;
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	align-items: center;
	line-height: 28px;
	font-size: 18px;
	padding: 10px 20px 10px 0;
	color: var(--vkws_landing--textarea--text-color);
	cursor: default;
	opacity: 0.5;
}

.input-root {
	text-align: left;
	border-radius: 8px;
	transition-property: border-color, background-color, opacity;
	transition-duration: 0.15s;
	transition-timing-function: ease-in-out;

	font-family: inherit;
	box-sizing: border-box;
	display: block;

	background: var(--vkws_landing--input--bg);
	border: var(--vkws_landing--input--border);

	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 28px;

	color: var(--vkws_landing--textarea--text-color);

	padding: 10px 20px;
}

.input-root:hover {
	background: var(--vkws_landing--input--bg_hover);
	border: var(--vkws_landing--input--border_hover);
}

.input-root:focus,
.input-root:active {
	border: 1px solid #D2D2D2;
	background: var(--vkws_landing--input--bg_focus);
	outline: none;
	border: var(--vkws_landing--input--border_focus);
}
.appearance-blue .input-root {
	background: var(--vkws_landing--input_blue--bg);
	border: var(--vkws_landing--input_blue--border);
	color: var(--vkws_landings--color--white);
}
.appearance-blue .input-root:hover {
	background: var(--vkws_landing--input_blue--bg_hover);
}
.appearance-blue .input-root:focus,
.appearance-blue .input-root:active {
	border: var(--vkws_landing--input_blue--border_active);
	background: inherit;
	outline: none;
}

.input-root:disabled {
	background: var(--vkws_landing--input--bg_disabled);
}

.state-invalid,
.input-root[aria-invalid='true'] {
	border-color: #FF8080;
}

.state-invalid:hover,
.input-root[aria-invalid='true']:hover {
	 border-color: #FF8080;
 }

.state-invalid:focus,
.state-invalid:active,
.input-root[aria-invalid='true']:focus ,
.input-root[aria-invalid='true']:active {
	 border-color: #FF8080;
}

.appearance-blue .input-root[aria-invalid='true'] {
	border-color: var(--vkws_landing--input_blue--border_invalid_color);
}

.appearance-blue .input-root[aria-invalid='true']:hover {
	border-color: var(--vkws_landing--input_blue--border_invalid_color);
}

.appearance-blue .input-root[aria-invalid='true']:focus ,
.appearance-blue .input-root[aria-invalid='true']:active {
	border-color: var(--vkws_landing--input_blue--border_invalid_color);
}
