.root {}

.variation_h1 {
	font-size: var(--vkws_landings--font_h1--font_size);
	line-height: var(--vkws_landings--font_h1--line_height);
	font-weight: 400;
}

@media screen and (min-width: 1024px) {
	.variation_h1 {
		font-size: var(--vkws_landings--font_h1--font_size--desktop);
		line-height: var(--vkws_landings--font_h1--line_height--desktop);
	}
}

.variation_h2 {
	font-size: var(--vkws_landings--font_h2--font_size);
	line-height: var(--vkws_landings--font_h2--line_height);
	font-weight: 400;
}

@media screen and (min-width: 1024px) {
	.variation_h2 {
		font-size: var(--vkws_landings--font_h2--font_size--desktop);
		line-height: var(--vkws_landings--font_h2--line_height--desktop);
	}
}

.variation_h3 {
	font-size: var(--vkws_landings--font_h3--font_size);
	line-height: var(--vkws_landings--font_h3--line_height);
	font-weight: 400;
}

@media screen and (min-width: 1024px) {
	.variation_h3 {
		font-size: var(--vkws_landings--font_h3--font_size--desktop);
		line-height: var(--vkws_landings--font_h3--line_height--desktop);
	}
}

.variation_h4 {
	font-size: var(--vkws_landings--font_h4--font_size);
	line-height: var(--vkws_landings--font_h4--line_height);
	font-weight: 400;
}

@media screen and (min-width: 1024px) {
	.variation_h4 {
		font-size: var(--vkws_landings--font_h4--font_size--desktop);
		line-height: var(--vkws_landings--font_h4--line_height--desktop);
		font-weight: 500;
	}
}

.variation_text {
	font-size: var(--vkws_landings--font_text--font_size);
	line-height: var(--vkws_landings--font_text--line_height);
	font-weight: 400;
	opacity: 0.7;
}

@media screen and (min-width: 1024px) {
	.variation_text {
		font-size: var(--vkws_landings--font_text--font_size--desktop);
		line-height: var(--vkws_landings--font_text--line_height--desktop);
	}
}

.variation_text15 {
	font-size: 15px;
	line-height: 20px;
	font-weight: 400;
	opacity: 0.7;
}

.variation_action {
	font-size: var(--vkws_landings--font_action--font_size);
	line-height: var(--vkws_landings--font_action--line_height);
	font-weight: 400;
}

.variation_accent-text {
	font-size: var(--vkws_landings--font_accent_text--font_size);
	line-height: var(--vkws_landings--font_accent_text--line_height);
	opacity: 0.7;
}

@media screen and (min-width: 1024px) {
	.variation_accent-text {
		font-size: var(--vkws_landings--font_accent_text--font_size--desktop);
		line-height: var(--vkws_landings--font_accent_text--line_height--desktop);
	}
}

.variation_list {
	font-size: var(--vkws_landings--font_list--font_size);
	line-height: var(--vkws_landings--font_list--line_height);
}

@media screen and (min-width: 1024px) {
	.variation_list {
		font-size: var(--vkws_landings--font_list--font_size--desktop);
		line-height: var(--vkws_landings--font_list--line_height--desktop);
	}
}

.variation_list-mini {
	font-size: var(--vkws_landings--font_list_mini--font_size);
	line-height: var(--vkws_landings--font_list_mini--line_height);
	opacity: 0.7;
	font-weight: 400;
}

@media screen and (min-width: 1024px) {
	.variation_list-mini {
		font-size: var(--vkws_landings--font_list_mini--font_size--desktop);
		line-height: var(--vkws_landings--font_list_mini--line_height--desktop);
	}
}

.variation_list-maxi {
	font-size: var(--vkws_landings--font_list_maxi--font_size);
	line-height: var(--vkws_landings--font_list_maxi--line_height);
	font-weight: 400;
}

@media screen and (min-width: 1440px) {
	.variation_list-maxi {
		font-size: var(--vkws_landings--font_list_maxi--font_size--desktop);
		line-height: var(--vkws_landings--font_list_maxi--line_height--desktop);
	}
}

.variation_sub-line {
	font-weight: 400;
	font-size: var(--vkws_landings--font_sub_line_font_size);
	line-height: var(--vkws_landings--font_sub_line--line_height);
}

@media screen and (min-width: 1024px) {
	.variation_sub-line {
		font-size: var(--vkws_landings--font_sub_line--font_size--tablet);
		line-height: var(--vkws_landings--font_sub_line--line_height--tablet);
	}
}

@media screen and (min-width: 1440px) {
	.variation_sub-line {
		font-size: var(--vkws_landings--font_sub_line--font_size--desktop);
		line-height: var(--vkws_landings--font_sub_line--line_height--desktop);
	}
}
