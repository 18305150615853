.control {
	width: 100%;
	resize: none;
}

.button-wrapper {
	display: flex;
	justify-content: center;
	margin-top: 24px;
	flex-direction: column;
	gap: 16px;
}

.button {
	font-size: 18px;
	font-weight: 400;
	line-height: 23px;
	border-radius: 14px;
}

.field-error {
	display: block;

	margin-top: 8px;
	font-weight: 400;
	font-size: 15px;
	line-height: 20px;

	color: var(--vkws_landings--color--text-negative);
}

.input-wrapper {
	margin-top: 21px;
	position: relative;
	flex-grow: 1;
}

.with-nameplate {
	padding-right: 95px;
}

.checkboxes-wrapper {
	margin-top: 12px;
}

.checkbox-label {
	color: var(--vkws_landing--dialog--description--color);
}

.label {
	display: flex;
	align-items: center;
	margin-top: 13px;
	color: var(--vkws_landing--dialog--description--color);
}

.terms-link {
	color: #1A85FF;
}

.form {
	margin-top: 18px;
}

@media screen and (min-width: 1024px) {
	.button-wrapper {
		margin-top: 24px;
		flex-direction: row;
		justify-content: left;
	}

	.domains-control {
		margin-top: 0;
		margin-bottom: 0;
	}
}

.input {
	height: 58px;
	background: rgba(255, 255, 255, 0.08) !important;
	color: #D9DADD !important;
	border: none !important;
	border-radius: 12px;
}
