:root {
	--vkws_landings--color--main_blue: #0077FF;
	--vkws_landings--color--dark-turquoise: #00D3E6;
	--vkws_landings--color--light-gray: #EDF0F3;
	--vkws_landings--color--ultralight-gray: #F9FAFB;
	--vkws_landings--color--white: #FFFFFF;
	--vkws_landings--color--gray-white: #E7ECF3;
	--vkws_landings--color--dark-grey: #111111;
	--vkws_landings--color--light-grey: #303030;
	--vkws_landings--color--brainstem-grey: #B5B5B5;
	--vkws_landings--color--nero-black: #242424;
	--vkws_landings--color--text-negative: #FF5C5C;
	--vkws_landings--color--raisin-black: #212121;
	--vkws_landings--color--nearly-black: #050B15;
	--vkws_landings--color--black: #000000;
	--vkws_landings--color--border-blue: #2688EB;
	--vkws_landings--color--note-blue: #92C2FA;
	--vkws_landings--color--subtitle-brown: #4D4D4D;
	--vkws_landings--color--light-slate-gray: #7C8A9A;
	--vkws_landings--color--brilliant-azure: #3D98FF;
	--vkws_landings--color--azureish-white: #E3E8F2;
	--vkws_landings--color--cultured: #F6F7F9;
	--vkws_landings--color--light-blue: #F5FAFF;
	--vkws_landings--comparison-table--border-color: #E3E3E3;
	--vkws_landings--color--note-light-gray: #B7B7B7;
	--vkws_landings--color--menu--dark-gray: #1A1A1A;
	--vkws_landings--color--menu--light-gray: #EAECF0;
	--vkws_landings--color--turquoise: #00D9ED;
	--vkws_landings--color--jet-black: #1D1D1F;
	--vkws_landings--color--charcoal: #090909;
	--vkws_landings--color--slate: #B3B3B4;
	--vkws_landings--color--promo-grey: #818C99;
	--vkws_landings--color--desc-grey: #BBBBBC;
	--vkws_landings--color--instruments-card-desc-grey: var(--vkws_landings--color--desc-grey);
	--vkws_landings--color--variant-card-desc--color: #CACACA;
	--vkws_landings--link-blue--color: #3C82FD;
	--vkws_landings--goal-icon--bg: #181818;
	--vkws_landings--goal-text--color: #F1F1F1;
	--vkws_landings--goal-block--border-color: #5C5C5C;
	--vkws_landings--goal-block--color: #6B6B6B;
	--vkws_landings--goal-block--bg: #282828;
	--vkws_landings--messengers-block-title--color: #F9F9F9;

	--vkws_landings--color_body: var(--vkws_landings--color--black);

	--vkws_landings--bg_body: var(--vkws_landings--color--white);

	--vkws_landings--horizontal-padding: 20px;
	--vkws_landings--horizontal-padding--desktop: 40px;
	--vkws_landings--horizontal-padding--mobile: 10px;

	--vkws_landings--portal_header--height: 36px;
	--vkws_landings--portal_header--background_color: var(--vkws_landings--color--black);
	--vkws_landings--dialog-close--color: #D9DADD;
	--vkws_landings--dialog-text--color: #E7E8EA;

	--vkws_landings--font_h1--font_size: 28px;
	--vkws_landings--font_h1--line_height: 34px;
	--vkws_landings--font_h1--font_size--desktop: 58px;
	--vkws_landings--font_h1--line_height--desktop: 74px;
	--vkws_landings--font_h2--font_size: 27px;
	--vkws_landings--font_h2--line_height: 30px;
	--vkws_landings--font_h2--font_size--desktop: 56px;
	--vkws_landings--font_h2--line_height--desktop: 66px;
	--vkws_landings--font_h3--font_size: 26px;
	--vkws_landings--font_h3--line_height: 30px;
	--vkws_landings--font_h3--font_size--desktop: 38px;
	--vkws_landings--font_h3--line_height--desktop: 44px;
	--vkws_landings--font_h4--font_size: 20px;
	--vkws_landings--font_h4--line_height: 26px;
	--vkws_landings--font_h4--font_size--desktop: 28px;
	--vkws_landings--font_h4--line_height--desktop: 32px;
	--vkws_landings--font_h3--font_size--mobile: 20px;
	--vkws_landings--font_h3--line_height--mobile: 26px;
	--vkws_landings--font_action--font_size: 18px;
	--vkws_landings--font_action--line_height: 22, 5px;
	--vkws_landings--font_accent_text--font_size: 16px;
	--vkws_landings--font_accent_text--line_height: 24px;
	--vkws_landings--font_accent_text--font_size--desktop: 24px;
	--vkws_landings--font_accent_text--line_height--desktop: 34px;
	--vkws_landings--font_accent_text--line_height--mobile: 22px;
	--vkws_landings--font_list--font_size: 20px;
	--vkws_landings--font_list--line_height: 32px;
	--vkws_landings--font_list--font_size--desktop: 24px;
	--vkws_landings--font_list--line_height--desktop: 32px;
	--vkws_landings--font_text--font_size: 16px;
	--vkws_landings--font_text--line_height: 22px;
	--vkws_landings--font_text--font_size--desktop: 20px;
	--vkws_landings--font_text--line_height--desktop: 28px;
	--vkws_landings--font_list_mini--font_size: 16px;
	--vkws_landings--font_list_mini--line_height: 28px;
	--vkws_landings--font_list_mini--font_size--desktop: 18px;
	--vkws_landings--font_list_mini--line_height--desktop: 28px;
	--vkws_landings--font_list_maxi--font_size: 16px;
	--vkws_landings--font_list_maxi--line_height: 22px;
	--vkws_landings--font_list_maxi--font_size--desktop: 28px;
	--vkws_landings--font_list_maxi--line_height--desktop: 32px;

	--vkws_landings--font_sub_line_font_size: 11px;
	--vkws_landings--font_sub_line--line_height: 15px;
	--vkws_landings--font_sub_line--font_size--tablet: 16px;
	--vkws_landings--font_sub_line--line_height--tablet: 22px;
	--vkws_landings--font_sub_line--font_size--desktop: 24px;
	--vkws_landings--font_sub_line--line_height--desktop: 34px;


	--vkws_landings--grey-border-color: #E3E3E3;
	--vkws_landings--blue-border-color: #07F;
	--vkws_landings--input--border-color: var(--vkws_landings--grey-border-color);
	--vkws_landings--input--border-color_dark: #151515;
	--vkws_landings--checkbox--border: 1px solid rgba(0, 16, 61, 0.12);

	--vkws_landings--button--max-width: 280px;
	--vkws_landings--button--border_radius: 12px;
	--vkws_landings--button--padding_horizontal: 30px;
	--vkws_landings--button--font_size: 18px;
	--vkws_landings--button--height: 49px;
	--vkws_landings--button--line-height: 49px;
	--vkws_landings--button--color: #FFFFFF;
	--vkws_landings--button_light--color: var(--vkws_landings--color--black);
	--vkws_landings--button_primary--background_color: var(--vkws_landings--color--main_blue);
	--vkws_landings--button_primary--background_color--hover: #1F87FF;
	--vkws_landings--button_primary--background_color--active: #006BE5;
	--vkws_landings--button--background_color--disabled: #005DC7;
	--vkws_landings--button--color--disabled: rgba(255, 255, 255, 0.64);
	--vkws_landings--button_contrast--background_color: #1E1E1E;
	--vkws_landings--button_contrast--background_color--hover: #262626;
	--vkws_landings--button_contrast--background_color--active: #050505;
	--vkws_landings--button_grey--color: #E7E8EA;
	--vkws_landings--button_grey--background_color: rgba(255, 255, 255, 0.12);
	--vkws_landings--button_grey--background_color--hover: linear-gradient(0deg, rgba(0, 16, 61, 0.04) 0%, rgba(0, 16, 61, 0.04) 100%), rgba(0, 16, 61, 0.06);
	--vkws_landings--button_grey--background_color--active: linear-gradient(0deg, rgba(0, 16, 61, 0.08) 0%, rgba(0, 16, 61, 0.08) 100%), rgba(0, 16, 61, 0.06);
	--vkws_landings--button_grey--background_color--disabled: rgba(255, 255, 255, 0.12);
	--vkws_landings--button_grey--color--disabled: #E7E8EA;
	--vkws_landings--button_light--background_color: var(--vkws_landings--color--gray-white);
	--vkws_landings--button_light--background_color--hover: #EDF3FC;
	--vkws_landings--button_light--background_color--active: #DBE2EC;

	--vkws_landings--tooltip--background_color: #232324;
	--vkws_landings--tooltip--color: #E7E8EA;
	--vkws_landings--tooltip--shadow: 0 10px 36px 0 rgba(0, 16, 61, 0.08), 0 6px 20px 0 rgba(0, 16, 61, 0.06), 0 6px 12px 0 rgba(0, 16, 61, 0.06), 0 0 6px 0 rgba(0, 16, 61, 0.06);

	--vkws_landings--header_button_move--bg: var(--vkws_landings--color--ultralight-gray);
	--vkws_landings--header_button_move--bg--hover: #F4F6FA;
	--vkws_landings--header_button_move--bg--active: var(--vkws_landings--color--menu--light-gray);
	--vkws_landings--header_button_move--bg_dark: rgba(255, 255, 255, 0.07);
	--vkws_landings--header_button_move--bg_dark--hover: rgba(249, 250, 251, 0.09);
	--vkws_landings--header_button_move--bg_dark--active: rgba(243, 244, 245, 0.05);

	--vkws_landings--header_button_project--bg: var(--vkws_landings--color--main_blue);
	--vkws_landings--header_button_project--bg--hover: var(--vkws_landings--button_primary--background_color--hover);
	--vkws_landings--header_button_project--bg--active: var(--vkws_landings--button_primary--background_color--active);

	--vkws_landings--header_button_light--background_color: var(--vkws_landings--color--white);
	--vkws_landings--header_button_light--background_color--hover: var(--vkws_landings--color--ultralight-gray);
	--vkws_landings--header_button_light--background_color--active: var(--vkws_landings--color--ultralight-gray);
	--vkws_landings--header_button_contrast--background_color: var(--vkws_landings--color--menu--dark-gray);
	--vkws_landings--header_button_contrast--background_color--hover: rgba(255, 255, 255, 0.07);
	--vkws_landings--header_button_contrast--background_color--active: rgba(255, 255, 255, 0.05);

	--vkws_landings--advanced_header--mobile-height: 136px;
	--vkws_landings--advanced_header--desktop-height: 88px;

	--vkws_landings--transition--duration: 0.4s;
	--vkws_landings--transition--timing-function: ease-out;

	--vkws_landings--header--z-index: 2;
	--vkws_landings--floating_toolbar--z-index: 1;
	--vkws_landings--image_above_filter--z-index: 1;
	--vkws_landings--image_background--z-index: 0;
	--vkws_landings--app_menu--z-index: calc(1 + var(--vkws_landings--floating_toolbar--z-index));
	--vkws_landings--portal_elements--z-index: calc(1 + var(--vkws_landings--app_menu--z-index));
	--vkws_landings--portal-menu--z-index: 1003;
	--vkws_landings--dialogs--z-index: calc(1 + var(--vkws_landings--portal-menu--z-index));
	--vkws_landings--app-nav--z-index: calc(1 + var(--vkws_landings--dialogs--z-index));
	--vkws_landings--dropdown-menu--z-index: calc(1 + var(--vkws_landings--app-nav--z-index));
	--vkws_landings--sticky-menu--z-index: calc(1 + var(--vkws_landings--portal-menu--z-index));

	--vkws_landings--with-breakpoints--tablet: 824px;

	--vkws_landings--default-font--family: "VK Sans Display", "Proxima Nova", Arial, Helvetica, "Helvetica Neue", sans-serif;
}
