.root {
	width: 100%;
	height: 100%;
	margin: auto;
	background: var(--vkws_landing--dialog--bg);
	padding: 18px var(--vkws_landings--horizontal-padding);

	display: flex;
	flex-direction: column;

	overflow: auto;

	position: relative;

	pointer-events: auto;
}

.root:focus {
	outline: none;
}

@media screen and (min-width: 1024px) {
	.root {
		padding: 46px 40px;
	}

	.root:not(.state_full-screen) {
		width: 660px;
		height: auto;
		min-height: 200px;
		border-radius: 16px;
		box-shadow: 0 2px 3px rgba(0, 0, 0, 0.07), 0 4px 24px rgba(0, 0, 0, 0.15);
	}


}
