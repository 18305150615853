@import '@paradigm/tokens/base/cssVars/declarations/index.css';

:root {
	/* burger-menu */
	--vkws_landing--burger--icon-open: var(--vkws_landings--color--main_blue);
	--vkws_landing--burger--bg: #ffffff;
	--vkws_landing--checkbox-background-color-checked: var(--paradigm--color_primary--normal);
	--vkws_landing--checkbox-background-color-disabled: var(--paradigm--color_bg_secondary--normal);
	--vkws_landing--checkbox-background-color-hover: #ffffff;
	--vkws_landing--checkbox-background-color-idle: #ffffff;
	--vkws_landing--checkbox-border-color-disabled: var(--paradigm--color_border--normal);
	--vkws_landing--checkbox-border-color-hover: var(--paradigm--color_border--hover);
	--vkws_landing--checkbox-border-color-idle: var(--paradigm--color_border--normal);
	--vkws_landing--checkbox-border-radius-checkbox: var(--paradigm--size_control_check_border_radius);
	--vkws_landing--checkbox-figure-color-checked: #ffffff;
	--vkws_landing--checkbox-figure-color-disabled: var(--paradigm--color_icon--normal);
	--vkws_landing--checkbox-figure-color-hover: var(--paradigm--color_icon--normal);
	--vkws_landing--checkbox-figure-color-indeterminate: var(--paradigm--color_icon--normal);
	--vkws_landing--checkbox-shadow: none;
	/* checkbox-or-radio */
	--vkws_landing--checkbox-size: var(--paradigm--size_control_check_width);
	--vkws_landing--checkbox-spacing: 12px;
	/* dialog */
	--vkws_landing--dialog--backdrop-bg: rgba(0, 0, 0, 0.35);
	--vkws_landing--dialog--bg: var(--vkws_landings--color--white);
	--vkws_landing--dialog--close-icon--color: none;
	--vkws_landing--dialog--description: inherit;
	--vkws_landing--dialog--title: inherit;
	--vkws_landing--faq_question--border-color: var(--vkws_landings--input--border-color);
	/* faq */
	--vkws_landing--faq_quesion--color: var(--vkws_landings--color--nearly-black);
	/* footer */
	--vkws_landing--footer--bg: #eff1f5;
	--vkws_landing--footer--border-top-color: #e3e3e3;
	--vkws_landing--footer--border-top-opacity: 1;
	--vkws_landing--footer--menu-text-color: #313131;
	--vkws_landing--footer_title--color: var(--vkws_landings--color--nearly-black);
	--vkws_landing--input--bg: rgba(237, 240, 243, 0.39);
	--vkws_landing--input--bg_disabled: rgba(237, 240, 243, 0.39);
	--vkws_landing--input--bg_focus: rgba(255, 255, 255, 1);
	--vkws_landing--input--bg_hover: rgba(237, 240, 243, 0.15);
	--vkws_landing--input--border: 1px solid var(--vkws_landings--input--border-color);
	--vkws_landing--input--border_focus: 1px solid #d2d2d2;
	--vkws_landing--input--border_hover: 1px solid var(--vkws_landings--input--border-color);
	--vkws_landing--input--required-star--color: #ff8080;
	--vkws_landing--input--required-star-light--color: #FF9999;
	/* input */
	--vkws_landing--input--text-color: rgba(0, 0, 0);
	--vkws_landing--form-feedback-link--text-color: #07F;
	--vkws_landing--form-feedback-questions--text-color: #6B6B6B;
	--vkws_landing--mobile-product-menu--color: #ffffff;
	--vkws_landing--input_blue--border: 1px solid #E3E3E3;
	--vkws_landing--input_blue--border_active: 1px solid #D2D2D2;
	--vkws_landing--input_blue--border_invalid_color: #FF9999;
	--vkws_landing--input_blue--bg: rgba(237, 240, 243, 0.39);
	--vkws_landing--input_blue--bg_hover: rgba(237, 240, 243, 0.15);
	/* product-menu */
	--vkws_landing--product-menu--bg: #252525;
	--vkws_landing--product-menu--bg-hover: #373737;
	--vkws_landing--product-menu--color: #ffffff;
	--vkws_landing--advantages-item--bg: #EFF1F5;
	--vkws_landing--advantages-separate--bg: #E3E3E3;
	--vkws_landing--advantages-title--color: #111111;
	--vkws_landing--required-blue--color: #01EAFF;
	--vkws_landing--textarea--bg: rgba(237, 240, 243, 0.39);
	--vkws_landing--textarea--bg_focus: rgba(255, 255, 255, 1);
	--vkws_landing--textarea--bg_hover: rgba(237, 240, 243, 0.15);
	--vkws_landing--textarea--border: 1px solid var(--vkws_landings--input--border-color);
	--vkws_landing--textarea--border_focus: 1px solid #d2d2d2;
	--vkws_landing--textarea--border_hover: 1px solid var(--vkws_landings--input--border-color);
	/* textarea */
	--vkws_landing--textarea--text-color: rgba(0, 0, 0);

	/* header */
	--vkws_landing--advanced-menu--bg-color: var(--vkws_landings--color--dark-grey);
	--vkws_landing--navbar-menu-item--color: var(--vkws_landings--color--black);
	--vkws_landing--navbar-menu-item--color-dark: var(--vkws_landings--color--white);
	--vkws_landings--header_content-navbar--background_color: transparent;
	--vkws_landings--header_content-navbar--background_color-dark: var(--vkws_landings--color--raisin-black);
	--vkws_landings--header_button_light--background_color: transparent;
	--vkws_landings--header_button_light--background_color--active: var(--vkws_landings--color--raisin-black);
	--vkws_landings--header_button_light--background_color--hover: var(--vkws_landings--color--raisin-black);

	--vkws_landing--toogle-menu-item-title--color: var(--vkws_landings--color--white);
	--vkws_landing--toogle-menu-item-title--color_active: var(--vkws_landings--color--dark-turquoise);
	--vkws_landing--toogle-menu-item-icon--color: var(--vkws_landings--color--white);
	--vkws_landing--toogle-menu-item-icon--color_active: var(--vkws_landings--color--dark-turquoise);
	--vkws_landing--toogle-menu-item-desc--color: #A8A8A8;
	--vkws_landing--toogle-menu-item-desc--color_active: var(--vkws_landings--color--white);
	--vkws_landing--toogle-menu-item--bg_hover: var(--vkws_landings--color--black);
	--vkws_landing--toogle-menu-item--bg_active: var(--vkws_landings--color--black);

	/*support block*/
	--vkws_landing--support--bg: #eff1f5;

	--vkws_landing--platform-card--bg: #090909;
	--vkws_landing--solutions-card--bg: #1D1D1F;
	--vkws_landing--triggers-card--bg: #1D1D1F;
	--vkws_landing--blog-logo--color: #333337;

	--vkws_landing--footer--bg: var(--vkws_landings--color--black);
	--vkws_landing--footer_title--color: var(--vkws_landings--color--white);
	--vkws_landing--footer--border-top-color: #f9f9f9;
	--vkws_landing--footer--border-top-opacity: 0.1;
	--vkws_landing--footer--menu-text-color: #f9f9f9;
}
