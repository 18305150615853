.root {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	justify-items: flex-start;
	flex: 0 1 auto;
	max-width: 100%;

	font-size: var(--vkws_landings--button--font_size);

	position: relative;
	box-sizing: border-box;
	text-decoration: none;
	border: none;
	margin: 0;
	padding: 0 var(--vkws_landings--button--padding_horizontal);
	user-select: none;

	border-radius: var(--vkws_landings--button--border_radius);
	cursor: pointer;
	white-space: nowrap;
	vertical-align: middle;
	touch-action: manipulation;

	color: var(--vkws_landings--button--color);
	background-color: var(--vkws_landings--button_primary--background_color);

	line-height: var(--vkws_landings--button--line-height);
	height: var(--vkws_landings--button--height);
	transition: background-color var(--vkws_landings--transition--duration) var(--vkws_landings--transition--timing-function);
}

.root:hover,
.root:focus {
	background-color: var(--vkws_landings--button_primary--background_color--hover);
}

.root:active {
	background-color: var(--vkws_landings--button_primary--background_color--active);
}

.root:focus:not(:-moz-focusring) {
	outline: none;
}

.root:focus:not(:focus-visible) {
	outline: none;
}

.root.state-disabled,
.root.state-disabled:hover,
.root.state-disabled:focus,
.root.state-disabled:active {
	color: var(--vkws_landings--button--color--disabled);
	background-color: var(--vkws_landings--button--background_color--disabled);
}

.state-full-width {
	flex-grow: 1;
	width: 100%;
}

.appearance-contrast {
	background-color: var(--vkws_landings--button_contrast--background_color);
}

.appearance-contrast:hover,
.appearance-contrast:focus {
	background-color: var(--vkws_landings--button_contrast--background_color--hover);
}

.appearance-contrast:active {
	background-color: var(--vkws_landings--button_contrast--background_color--active);
}

.appearance-light {
	background-color: var(--vkws_landings--button_light--background_color);
	color: var(--vkws_landings--button_light--color);
}

.appearance-light:hover,
.appearance-light:focus {
	background-color: var(--vkws_landings--button_light--background_color--hover);
}

.appearance-light:active {
	background-color: var(--vkws_landings--button_light--background_color--active);
}

.appearance-outline {
	background-color: inherit;
	border: 1px solid var(--vkws_landings--color--main_blue);
	color: var(--vkws_landings--color--main_blue);
}

.appearance-outline:hover,
.appearance-outline:focus,
.appearance-outline:active {
	color: var(--vkws_landings--color--white);
}

.appearance-grey {
	color: var(--vkws_landings--button_grey--color);
	background-color: var(--vkws_landings--button_grey--background_color);
}

.appearance-grey:hover,
.appearance-grey:focus {
	background-color: var(--vkws_landings--button_grey--background_color--hover);
}

.appearance-grey:active {
	background-color: var(--vkws_landings--button_grey--background_color--active);
}

.appearance-grey.state-disabled,
.appearance-grey.state-disabled:hover,
.appearance-grey.state-disabled:focus,
.appearance-grey.state-disabled:active {
	opacity: 0.48;
	color: var(--vkws_landings--button_grey--color--disabled);
	background-color: var(--vkws_landings--button_grey--background_color--disabled);
}


.appearance-dark {
	background-color: var(--vkws_landings--color--white);
	color: var(--vkws_landings--color--black);
}

.appearance-dark:hover,
.appearance-dark:focus,
.appearance-dark:active {
	color: var(--vkws_landings--color--white);
	background-color: var(--vkws_landings--color--raisin-black);
}

.state-disabled,
.state-disabled:hover,
.state-disabled:focus,
.state-disabled:active {
	cursor: not-allowed;
}
